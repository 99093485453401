import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Slide from "./child-components/Slide";

function BubbleSlider({ slides }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0),
    manuallyChangeSlide = index => {
      if (index < 0) {
        setActiveSlideIndex(slides.length - 1);
      } else if (index >= slides.length) {
        setActiveSlideIndex(0);
      } else {
        setActiveSlideIndex(index);
      }
    };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveSlideIndex((activeSlideIndex + 1) % slides?.length);
    }, 5000);
    return () => clearTimeout(timerId);
  }, [activeSlideIndex, slides?.length]);

  return (
    <section className={"dark-blue-bg"}>
      {slides && (
        <Slide
          slideData={slides[activeSlideIndex]}
          setActive={manuallyChangeSlide}
          slideCount={slides.length}
          activeSlideIndex={activeSlideIndex}
        />
      )}
    </section>
  );
}

BubbleSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      factsHighlight: PropTypes.string,
      image: PropTypes.shape({
        fluid: PropTypes.shape({
          srcSet: PropTypes.string,
        }),
        description: PropTypes.string,
      }),
      name: PropTypes.string,
    })
  ),
};

export default BubbleSlider;
