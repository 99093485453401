import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { theme } from "../../../utils/global-styles/theme";
import Bubble from "./Bubble";
import { useSwipeable, Swipeable } from "react-swipeable";

const buildBubbles = (totalCount, activeIndex, setActive) => {
  const bubbles = [];
  for (let i = 0; i < totalCount; i++) {
    bubbles.push(
      <Bubble
        active={activeIndex === i}
        setActive={setActive}
        index={i}
        key={i}
      />
    );
  }
  return bubbles;
};

function Slide({ slideData, slideCount, setActive, activeSlideIndex }) {
  const StyledSliderGridWrapper = styled(Swipeable)`
      display: grid;
      grid-template-columns: 342px 1fr 342px;
      grid-template-rows: 130px 1fr auto 50px;
      height: max-content;
      font-family: ${theme.fontFaces.headers};

      @media only screen and (max-width: ${theme.breakpoints.helper1350}) {
        grid-template-columns: 80px 1fr 80px;
      }

      @media only screen and (max-width: ${theme.breakpoints.desktopUp}) {
        grid-template-columns: 50px 1fr 50px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 64px;
        padding: 0 10px;
      }
    `,
    FactContentSubGrid = styled.div`
      grid-area: 2 / 2 / span 1 / span 1;
      display: grid;
      grid-template-columns: 110px 1fr;
      grid-template-rows: 1fr 95px;

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template-rows: 1fr 120px;
      }

      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        grid-template-rows: 1fr 150px;
      }

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 1 / 1 / span 1 / span 1;
        display: grid;
        grid-template-columns: 28px 1fr;
        grid-template-rows: 1fr 300px;
        padding-top: 50px;
      }

      -webkit-animation: fadein 2s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s ease-in-out; /* Firefox < 16 */
      -ms-animation: fadein 2s ease-in-out; /* Internet Explorer */
      -o-animation: fadein 2s ease-in-out; /* Opera < 12.1 */
      animation: fadein 2s ease-in-out;
    `,
    StyledDescription = styled("span")`
      font-size: 25px;
      font-weight: bold;
      letter-spacing: -1.04px;
      line-height: 30px;
    `,
    StyledFactHighlightImageContainer = styled.div`
      grid-area: 1 / 1 / span 1 / span 2;
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;

      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 1 / 1 / span 1 / span 1;
        padding-bottom: 0;
      }
    `,
    StyledFactHighlightWrapper = styled("div")`
      display: flex;
      align-self: flex-end;
      justify-content: flex-start;
      align-items: baseline;
      z-index: 1;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 1 / 2 / span 2 / span 3;
      }
    `,
    StyledImage = styled(Img)`
      height: 316px;
      width: 475px;
      @media only screen and (max-width: ${theme.breakpoints.helper1100}) {
        display: none;
      }
    `,
    StyledFactDescriptionWrapper = styled("div")`
      grid-area: 2 / 1 / span 1 / span 2;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 2 / 2 / span 1 / span 1;
        padding: 20px 0;
      }
    `,
    StyledBubbleWrapper = styled("div")`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      grid-area: 3 / 2 / span 1 / span 1;
      padding-top: 50px;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        grid-area: 2 / 1 / span 1 / span 1;
        padding: 0;
      }
    `,
    StyledNumberSuffix = styled("h2")`
      font-size: 130px;
      font-weight: 300;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 80px;
        font-weight: 300;
        line-height: 70px;
      }
    `,
    StyledH1SectionOfHighlight = styled("h1")`
      font-size: 150px;
      font-weight: 700;
      @media only screen and (max-width: ${theme.breakpoints.mobile}) {
        font-size: 130px;
        font-weight: 700;
        line-height: 105px;
      }
    `,
    parseNumber = (number, unit) => (
      <StyledFactHighlightWrapper className="white">
        <StyledH1SectionOfHighlight>{number}</StyledH1SectionOfHighlight>
        <StyledNumberSuffix>{unit}</StyledNumberSuffix>
      </StyledFactHighlightWrapper>
    ),
    formattedFactHighlight = rawText => {
      const text = rawText.toLowerCase();
      if (text.includes("trillion") || text.includes(",000,000,000,000")) {
        return text.includes("trillion")
          ? parseNumber(text.replace(/trillion/g, "").replace(/\s/g, ""), "T")
          : parseNumber(
              text.replace(/,000,000,000,000/g, "").replace(/\s/g, ""),
              "T"
            );
      } else if (text.includes("billion") || text.includes(",000,000,000")) {
        return text.includes("billion")
          ? parseNumber(text.replace(/billion/g, "").replace(/\s/g, ""), "B")
          : parseNumber(
              text.replace(/,000,000,000/g, "").replace(/\s/g, ""),
              "B"
            );
      } else if (text.includes("million") || text.includes(",000,000")) {
        return text.includes("million")
          ? parseNumber(text.replace(/million/g, "").replace(/\s/g, ""), "M")
          : parseNumber(text.replace(/,000,000/g, "").replace(/\s/g, ""), "M");
      } else if (text.includes("thousand") || text.includes(",000")) {
        return text.includes("thousand")
          ? parseNumber(text.replace(/thousand/g, "").replace(/\s/g, ""), "K")
          : parseNumber(text.replace(/,000/g, "").replace(/\s/g, ""), "K");
      } else if (text.includes("%")) {
        return parseNumber(text.replace(/%/g, "").replace(/\s/g, ""), "%");
      } else {
        return (
          <StyledFactHighlightWrapper className="white">
            <StyledH1SectionOfHighlight>{text}</StyledH1SectionOfHighlight>
          </StyledFactHighlightWrapper>
        );
      }
    };
  return (
    <StyledSliderGridWrapper
      onSwipedLeft={() => setActive(activeSlideIndex + 1)}
      onSwipedRight={() => setActive(activeSlideIndex - 1)}
    >
      <FactContentSubGrid>
        <StyledFactHighlightImageContainer>
          {formattedFactHighlight(slideData?.factsHighlight)}
          {/* {slideData.image.fluid.srcSet && (
            <StyledImage
              fluid={slideData.image.fluid}
              alt={slideData.image.description}
            />
          )} */}
        </StyledFactHighlightImageContainer>
        <StyledFactDescriptionWrapper>
          <StyledDescription className="white">
            {slideData?.description?.description}
          </StyledDescription>
        </StyledFactDescriptionWrapper>
      </FactContentSubGrid>
      <StyledBubbleWrapper>
        {buildBubbles(slideCount, activeSlideIndex, setActive)}
      </StyledBubbleWrapper>
    </StyledSliderGridWrapper>
  );
}

Slide.propTypes = {
  slideData: PropTypes.shape({
    description: PropTypes.shape({
      description: PropTypes.string,
    }),
    factsHighlight: PropTypes.string,
    image: PropTypes.shape({
      fluid: PropTypes.shape({
        srcSet: PropTypes.string,
      }),
      description: PropTypes.string,
    }),
    name: PropTypes.string,
  }),
  slideCount: PropTypes.number,
  setActive: PropTypes.func,
  activeSlideIndex: PropTypes.number,
};

export default Slide;
